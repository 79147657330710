<template>
  <ion-page>
    <ion-content id="main-content" :fullscreen="true">
      <div class="container">
        <div class="spinner-container"  v-if="loading">
          <ion-spinner name="lines" color="primary" class="spinner"></ion-spinner>
        </div>
        <div v-else>
          <div  v-if="locations.length > 0">
            <h1 @click="loading = !loading">Set Up Manned Station</h1>
            <span class="subtitle">
             Choose which location you are setting up at:
            </span>
        
            <div class="buttonBox">
            <Button
              v-for="(location, index) in locations"
              :text="location.name"
              variant="secondary"
              :key="index"
              class="location-button"
              @click="selectLocation(location)"
              :data-cy="'location-' + index"
              />
            </div>
          </div>
      
          <div v-else class="emptyState">
            <h1>No Locations Available</h1>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import ViewMixin from '@/mixins/views';
  import { mapActions, mapMutations, mapState } from 'vuex';
  import { IonPage, IonContent } from '@ionic/vue';

  import Button from '@/components/shared/buttons/Button';
  
  export default {
    components: {
      IonContent,
      IonPage,
      Button,
    },
  mixins:[ViewMixin],
    data() {
      return {
        loading: true,
      };
    },
  ionViewDidEnter(){
      this.incrementComponentKeyCounter()
    },
    computed: {
      ...mapState({
        locations: (state) => state.locations.items,
        user: (state) => state.auth.user,
      }),

      selectedLocation() {
        const data = localStorage.getItem('tithely:location');

        if (!data) {
          return;
        }

        return JSON.parse(data);
      },
    },

    ionViewWillEnter() {
      if (this.selectedLocation) {
        return this.selectLocation(this.selectedLocation);
      }

      this.getLocations(this.user.currentOrganizationId)
        .then(() => {
          this.loading = false;

          if (this.locations.length === 1) {
            this.selectLocation(this.locations[0]);
          }
        });
    },
    methods: {
      ...mapActions({
        getLocations: 'locations/get',
      }),

      ...mapMutations({
        setCurrent: 'locations/SET_CURRENT',
      }),

      selectLocation(location) {
        this.setCurrent(location);
        localStorage.setItem('tithely:location', JSON.stringify(location));
        this.$router.push({ name: 'LocationServices', params: { id: location.id } });
      },
    },
  };
</script>

<style scoped lang="scss">
  ion-content {
    --background: white;

    .container {

       .spinner-container {
    height: 100vh;
    width: 100%;
    position: absolute !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
  }
      h1{
        margin-top: 190px;
        margin-bottom: 24px;
      }

      .buttonBox{
        margin-top: 40px;
        ion-button {
          margin-bottom: 12px;
        }
      }
      
      .spinner {
        margin-bottom: 20px;
        margin-top: 20px;
      }
    }
  }
    @media screen and (max-height: 540px) {
     ion-content {
       .container {
         overflow: hidden;
         h1{
           margin-top: 55px;
         }
       }
     }
   }

   @media only screen and (orientation: portrait) {
   ion-content {
    .container{
      h1{
        margin-top: 0px;
        margin-bottom: 24px;
      }
      height: 90vh;
       display: flex;
       justify-content: center;
       align-items: center;
      overflow: hidden;
    } 
   } 
  }
</style>
